<template>
	<div>

		<TableList :columns="columns" :get_table_list="this.get_table_list" :slot_table_list="['op','icon']"
			ref="list">
			<template slot="left_btn">
				<div style="display: flex;width: 100%;">
					<div id="" style="flex: 1;" class="search_form">
						<span class="cq_text_require">*</span><span id=""> 企业可创建多个劳动合同关系的合同公司，并在此对其进行管理。</span>
					</div>
					<a-button class="float_left" v-auth="'change'" @click="add_open()" type="primary">添加合同公司</a-button>
				</div>

			</template>

			<template #op="{record}">
				<div class="op">
					<span v-auth="'change'" @click="get_info(record.id)" class="check">编辑</span>
					<a-divider v-auth="'change'" type="vertical" />
					<span v-auth="'change'" @click="merge_open(record)" class="check">合并</span>
					<a-divider v-auth="'change'" type="vertical" />
					<span v-auth="'delete'" class="check del" v-if="record.can_delete==1" @click="del_open(record.id)"> 删除 </span>
				</div>
			</template>
		</TableList>

		<EditPop ref="edit_pop" @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>

		<a-modal v-model:visible="delVisible" title="删除合同" @ok="delOk">
			<p>确认要删除该合同公司吗?</p>
		</a-modal>
		<a-modal v-model:visible="mergeVisible" title="提示" @ok="mergeOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="当前公司">
					<a-input disabled v-model:value="company_name" />
				</a-form-item>
				<a-form-item label="合并入">
					<a-select v-model:value="merge_company" placeholder="请选择合同">
						<a-select-option v-for='item in select_list' :value="item.value">
							{{item.label}}
						</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import TableList from "@/components/TableList";
	import {
		getCateList,
		changeCate,
		getCate,
		delCate
	} from "@/api/notice";
	import {
		getCompanyInfo,
		modify,
		getIndustryType,
		getContractCompanyList,
		getContractCompanyDetail,
		delContractCompany,
		mergeContractCompany,
		saveContractCompany,
		getContractCompanySelect,
		editContractCompany
	} from '@/api/enterprise.js'
	const columns = [{
			title: '合同公司名称',
			dataIndex: 'name',
			key: 'name',
			width: '160px',
		},
		{
			title: '公司简称',
			dataIndex: 'simple_name',
			key: 'simple_name',
			width: '120px',
		},
		{
			title: '公司法人',
			align: 'center',
			dataIndex: 'juridical_person',
			key: 'juridical_person',
			width: '120px',
		},
		{
			title: '公司联系人',
			dataIndex: 'contact_name',
			align: 'center',
			key: 'contact_name',

		},
		{
			title: '注册地址',
			dataIndex: 'address',
			align: 'center',
			key: 'address',
		},
		{
			title: '在职员工数',
			dataIndex: 'online_num',
			key: 'online_num',
			width: '120px',
		},
		{
			title: '离职员工数',
			dataIndex: 'offline_num',
			key: 'offline_num',
		},
		{
			title: '操作',
			dataIndex: 'op',
			key: 'op',
			width: '180px',
			scopedSlots: {
				customRender: 'op'
			},
		},
	];


	export default {
		name: "Index",
		components: {
			EditPop,
			TableList
		},
		data() {
			return {
				company_name: '',
				merge_company: '',
				labelColEdit: {
					span: 4
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 12
				},
				editType: 'add',
				get_table_list: getContractCompanyList,
				columns,
				select_list: [],
				delVisible: false,
				editVisible: false,
				mergeVisible: false,
				delCompany: false,
				visible: false,
				form_data: {
					title: '添加',
					list: [

						{
							type: 'text',
							name: 'name',
							title: '合同公司',
							options: {},
							required:true
						},
						{
							type: 'text',
							name: 'simple_name',
							title: '企业简称',
							options: {},
						},
						{
							type: 'text',
							name: 'juridical_person',
							title: '公司法人',
							options: {},
						},
						{
							type: 'text',
							name: 'contact_name',
							title: '公司联系人',
							options: {},
						},
						{
							type: 'area',
							name: 'addressList',
							title: '注册地址',
							options: {},
							placeholder: "请选择 省/市/区"
						},

						{
							type: 'text',
							name: 'register_address',
							title: '详细地址',
							options: {},
						},

					]
				}
			};
		},


		async created() {
			
		},
		methods: {
			add_open() {
				this.form_data.list.forEach(item => {			
				    if (item.name == "name") {
				       item.options.initialValue = ''
				    }
					if (item.name == "juridical_person") {
					   item.options.initialValue = ''
					}
					if (item.name == "register_address") {
					   item.options.initialValue = ''
					}
					if (item.name == "simple_name") {
					   item.options.initialValue = ''
					}
					if (item.name == "contact_name") {
					   item.options.initialValue = ''
					}
					if (item.name == "addressList") {
					   item.options.initialValue = []
					}	
				});
				this.visible = true
				this.editType = 'add'
			},
			mergeOk() {
				mergeContractCompany({
					data: {
						company_id: this.company_id,
						merge_company: this.merge_company
					}
				}).then(res => {
					this.mergeVisible = false
					this.company_id = ''
					this.company_name = ''
					this.merge_company = ''
					this.$refs.list.get_list()
				})
			},
			del_open(id) {
				this.company_id = id
				this.delVisible = true
			},
			merge_open(item) {
				this.getIndustry(item.id)
				this.company_id = item.id
				this.company_name = item.name
				this.mergeVisible = true
			},
			delOk() {
				delContractCompany({
					data: {
						company_id: this.company_id
					}
				}).then(res => {
					this.delVisible = false
					this.company_id = ''
					this.$refs.list.get_list()
				})
			},
			changeAddress(e) {
				this.formState2.province = e[0]
				this.formState2.city = e[1]
				this.formState2.district = e[2]
			},

			get_info(id) {
				getContractCompanyDetail({
					data: {
						company_id: id
					},
					
				}).then(res => {
					this.company_id = id
						this.form_data.list.forEach(item => {
							
						    if (item.name == "name") {
						       item.options.initialValue = res.data.list.name
						    }
							if (item.name == "juridical_person") {
							   item.options.initialValue = res.data.list.juridical_person
							}
							if (item.name == "register_address") {
							   item.options.initialValue = res.data.list.register_address
							}
							if (item.name == "simple_name") {
							   item.options.initialValue = res.data.list.simple_name
							}
							if (item.name == "contact_name") {
							   item.options.initialValue = res.data.list.contact_name
							}
							if (item.name == "addressList") {
								let address = [res.data.list.register_province,res.data.list.register_city,res.data.list.register_district]
							   item.options.initialValue = address
							}
						});
					this.visible = true
					this.editType = 'edit'
				})
			},
			submit(info) {
				let citys=''
				if( info.values.addressLitst){
					 citys = info.values.addressLitst.split(',')
				}
				let option = {
					name:info.values.name,
					simple_name: info.values.simple_name,
					juridical_person: info.values.juridical_person,
					register_province: citys[0],
					register_city:citys[1],
					contact_name:info.values.contact_name,
					register_district: citys[2],
					register_address:info.values.register_address,
				}
				if (this.editType == 'add') {
					saveContractCompany({
						data: option,
						info:true
					}).then(res => {
						this.visible = false
						this.$refs.list.get_list()

					})
				} else {
					option.company_id = this.company_id
					editContractCompany({
						data: option,
						info:true
					}).then(res => {
						this.visible = false
						this.$refs.list.get_list()
					})
				}
			},
			getIndustry(id) {
				getContractCompanySelect({data:{company_id:id}}).then(res => {
					this.select_list = res.data.list
				})
			},
			handle_submit() {

			}

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
	.check{
	    color: @cq_color;
	    cursor: pointer;
	}
	.del{
	    color: @cq_error;
	}
</style>
